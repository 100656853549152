import gql from 'graphql-tag';
import { ImageFields } from '../common-fields.queries';
import { FacetGroupFields, SelectedFacetGroupFields } from './search-facet-fields.queries';
import { PriceInfoWithRange, SearchProductDropFields } from './search-product-drop-fields.queries';

const TopFacetItemFields = gql`
	fragment TopFacetItemFields on TopFacetItem {
		title
		groupTitle
		image {
			...ImageFields
		}
		count
		weight
		selected
		facets {
			facetId
			value
		}
	}
	${ImageFields}
`;

export const PRODUCT_SEARCH = gql`
	query ProductSearch($input: ProductSearchInput!) {
		productSearch(input: $input) {
			... on SearchRedirect {
				url
			}
			... on ProductSearchResult {
				baseCategory
				businessCategory
				products {
					...SearchProductDropFields
				}
				facetGroups {
					...FacetGroupFields
				}
				selectedFacetGroups {
					...SelectedFacetGroupFields
				}
				breadcrumbs {
					name
					url
				}
				count
				searchId
				correctedSearchTerm
				searchEngine
				queryStep
				topFacets {
					...TopFacetItemFields
				}
				flagged
			}
		}
	}
	${SearchProductDropFields}
	${PriceInfoWithRange}
	${FacetGroupFields}
	${SelectedFacetGroupFields}
	${TopFacetItemFields}
`;

export const RELATED_QUERIES = gql`
	query RelatedQueries($query: String!) {
		relatedQueries(query: $query) {
			name
			url
		}
	}
`;

export const PRODUCT_SEARCH_FACET_GROUP = gql`
	query ProductSearchFacetGroup($input: ProductSearchFacetGroupInput!) {
		productSearchFacetGroup(input: $input) {
			id
			name
			facets {
				facetId
				groupId
				value
				count
			}
			metadata {
				hasMoreFacets
			}
		}
	}
`;
